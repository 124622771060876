<script>
import axios from 'axios';
import VTextbox from '@/components/Textbox.vue';
import Swal from 'sweetalert2';
import { cacheUserData } from '../helpers/user';

export default {
  components: {
    VTextbox,
  },
  inject: {
    $validator: '$validator',
  },
  model: {
    prop: 'data',
    event: 'change',
  },
  data() {
    return {
      userName: '',
      isUserNameDisabled: false,
      isPhoneDisabled: false,
      birthDate: null,
      isBirthNameDisabled: false,
      motherName: '',
      isMotherNameDisabled: false,
      documentType: '',
      phone: '',
    };
  },
  props: {
    data: Object,
    responsive: {
      type: Boolean,
      default: true,
    }
  },
  beforeMount() {
    if (this.data.tipo === 'PJ') {
      if (this.data.dataNascimento.length) {
        this.birthDate = this.$moment(this.data.dataNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY');
      } else {
        this.birthDate = this.$moment(this.data.dataNascimento, 'MM/DD/YYYY hh:mm:ss A' || 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
    } else {
      this.birthDate = this.$moment(this.data.dataNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    this.userName = this.data.nome;
    this.dataNascimento = this.birthDate;
    this.motherName = this.data.nomeMae;

    if (this.data.telefones) {
      this.phone = (this.data.telefones.ddd || '') + (this.data.telefones.numeroTelefone || '');
    } else {
      this.phone = '';
    }
    this.documentType = this.$store.getters.getDocumentType;
    this.checkInputUserNameValue();
    this.checkInputDataNascimentoValue();
    this.checkInputMotherNameValue();
    this.checkInputPhoneValue();
  },
  watch: {
    birthDate() {
      const date = this.$moment(this.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
      if (date !== 'Invalid date') {
        this.$set(this.data, 'dataNascimento', this.$moment(this.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
      }
    },
    phone(value) {
      const str = ((value).replace(/\D/g, '')).match(/^\(?(0?\d{2})\)?\s?(\d{4,5}\-?\d{4})$/);
      if (str) {
        [, this.data.telefones.ddd] = str;
        if (this.data.telefones.ddd.length === 2) {
          this.data.telefones.ddd = `0${this.data.telefones.ddd}`;
        }
        this.data.telefones.numeroTelefone = str[2].replace('-', '');
      }
    }
  },
  computed: {
    address: {
      get() {
        const addresses = this.data.enderecos ? this.data.enderecos : this.$set(this.data, 'enderecos', {});
        return Object.assign(addresses, { idTipoEndereco: 1 });
      },
      set(value) {
        this.$set(this.data, 'enderecos', value);
      },
    },
    // birthDate: {
    //   get(value) {
    //     console.log('get', this.data.dataNascimento)
    //     return this.data.dataNascimento ? this.$moment(this.data.dataNascimento, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
    //   },
    //   set(value) {
    //     console.log('set', value)
    //     this.$set(this.data, 'dataNascimento', this.$moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    //   },
    // },
    // phone: {
    //   get() {
    //     if (!this.data.telefones) {
    //       this.$set(this.data, 'telefones', { ddd: '', numeroTelefone: '' });
    //     }
    //     return (this.data.telefones.ddd || '') + (this.data.telefones.numeroTelefone || '');
    //   },
    //   set(value) {
    //     const str = ((value).replace(/\D/g, '')).match(/^\(?(0?\d{2})\)?\s?(\d{4,5}\-?\d{4})$/);
    //     if (str) {
    //       [, this.data.telefones.ddd] = str;
    //       // this.data.telefones.ddd = `0${this.data.telefones.ddd}`
    //       this.data.telefones.numeroTelefone = str[2].replace('-', '');
    //     }
    //   }
    // },
    unmaskedCEP: {
      get() {
        return this.address.cep ? this.address.cep.replace(/\-/g, '') : '';
      },
      set(value) {
        this.$set(this.address, 'cep', value.replace(/\-/g, ''));
      }
    },
    unmaskedCPF: {
      get() {
        return this.data.cpf ? this.data.cpf.replace(/\.|-/g, '') : '';
      },
      set(value) {
        this.$set(this.data, 'cpf', value.replace(/\.|-/g, ''));
      }
    },
    unmaskedCNPJ: {
      get() {
        return this.data.cnpj ? this.data.cnpj.replace(/\.|-|\//g, '') : '';
      },
      set(value) {
        this.$set(this.data, 'cnpj', value.replace(/\.|-|\//g, ''));
      }
    }
  },
  methods: {
    checkInputUserNameValue() {
      if (this.userName) {
        this.isUserNameDisabled = true;
      } else if (!this.userName) {
        this.isUserNameDisabled = false;
      }
    },
    checkInputDataNascimentoValue() {
      if (this.dataNascimento !== 'Invalid date') {
        this.isBirthNameDisabled = true;
      } else if (this.dataNascimento === 'Invalid date') {
        this.isBirthNameDisabled = false;
      }
    },
    checkInputMotherNameValue() {
      if (this.motherName) {
        this.isMotherNameDisabled = true;
      } else if (!this.motherName) {
        this.isMotherNameDisabled = false;
      }
    },
    checkInputPhoneValue() {
      if (this.phone) {
        this.isPhoneDisabled = true;
      } else if (!this.userName) {
        this.isPhoneDisabled = false;
      }
    },
    async cepInput(e) {
      const { value } = e.target;
      const cep = value.replace(/\D/g, '');
      // if (cep) {
      //   if (/^[0-9]{8}$/.test(cep)) {}
      // }
      if (cep && cep.length === 8) {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`, {})
          .then(({ data }) => { return ('erro' in data) ? false : data; })
          .catch(() => false)

        if (response) {
          Swal.fire({
            type: 'info',
            title: 'Confirme os dados do CEP informado',
            html: `
            <div class="swal2-cep" style="text-align: start; padding-left: 20px;">
              <div class="swal2-cep__item">
                <div class="swal2-cep__item__label"><strong>CEP:</strong>
                  ${response.cep}
                </div>
              </div>
              <div class="swal2-cep__item">
                <div class="swal2-cep__item__label"><strong>Logradouro:</strong>
                  ${response.logradouro}
                </div>
              </div>
              <div class="swal2-cep__item">
                <div class="swal2-cep__item__label"><strong>Bairro:</strong>
                  ${response.bairro}
                </div>
              </div>
              <div class="swal2-cep__item">
                <div class="swal2-cep__item__label"><strong>Cidade:</strong>
                  ${response.localidade}
                </div>
              </div>
              <div class="swal2-cep__item">
                <div class="swal2-cep__item__label"><strong>Estado:</strong>
                  ${response.uf}
                </div>
              </div>
            </div>
          `,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: false
          }).then((result) => {
            if (result.value) {
              const street = this.adjustLongAddresses(response.logradouro);
              const district = this.adjustLongAddresses(response.bairro);

              this.$set(this.address, 'uf', response.uf);
              this.$set(this.address, 'cidade', response.localidade);
              this.$set(this.address, 'bairro', district);
              this.$set(this.address, 'logradouro', street);
              this.$set(this.address, 'numero', '');
              this.$set(this.address, 'complemento', '');

              const addressLine = document.getElementsByName('account.street')[0];
              const neighborhood = document.getElementsByName('account.neighborhood')[0];
              const city = document.getElementsByName('account.city')[0];
              const state = document.getElementsByName('account.state')[0];

              // if (!street) addressLine.disabled = false;
              addressLine.disabled = !!street;
              // if (!response.bairro) neighborhood.disabled = false;
              neighborhood.disabled = !!response.bairro;
              // if (!response.localidade) city.disabled = false;
              city.disabled = !!response.localidade;
              // if (!response.uf) state.disabled = false;
              state.disabled = !!response.uf;

              this.$forceUpdate();
              setTimeout(() => {
                document.getElementsByName('account.number')[0].focus();
              }, 550);
            } else if (!result.value) {
              this.unmaskedCEP = '';
            } else {
              cacheUserData()
            }
          });
        } else {
          Swal.fire({
            title: 'CEP não encontrado',
            text: 'Verifique se o CEP informado está correto',
            type: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: false
          });
        }
      }
    },
    adjustLongAddresses(street = '') {
      if (street.length > 40) {
        const streetName = street.split(' ');
        // nao mudar o primeiro nem ultimo nome mas os do meio com mais de 3 letras usar somente a primeira letra com ponto
        /* eslint-disable no-plusplus */
        for (let i = streetName.length - 1; i >= 0; i--) {
          const [index, item] = [i, streetName[i]];

          // eslint-disable-next-line no-continue
          if (index === 0 || index === streetName.length - 1) continue;
          else if (item.length > 3) streetName[i] = (`${item[0]}.`).toUpperCase();
          // verifica se a rua ja tem menos de 40 caracteres
          if (streetName.join(' ').length < 40) break;
        }
        return streetName.join(' ');
      }
      return street;
    },
    houseNumber(num) {
      const { value } = num.target;
      const number = value.replace(/[^/SNsn0123456789]+/g, '');
      this.$set(this.address, 'numero', number);
    }
  },
  mounted() {
    const { name, path } = this.$route;
    if (name !== 'VirtualCardSignUp' && path !== '/cadastro/cartao-virtual') return;
    const addressLine = document.getElementsByName('account.street')[0];
    const neighborhood = document.getElementsByName('account.neighborhood')[0];
    addressLine.addEventListener('input', (e) => {
      const { value } = e.target;
      const street = this.adjustLongAddresses(value);
      this.$set(this.address, 'logradouro', street);
    });
    neighborhood.addEventListener('input', (e) => {
      const { value } = e.target;
      const district = this.adjustLongAddresses(value);
      this.$set(this.address, 'bairro', district);
    });
    // set disable
    const city = document.getElementsByName('account.city')[0];
    const state = document.getElementsByName('account.state')[0];
    // const cep = document.getElementsByName('account.cep')[0];

    addressLine.disabled = true;
    neighborhood.disabled = true;
    city.disabled = true;
    state.disabled = true;
  }
};
</script>

<template>
  <div :class="{ responsive }" class="user-account">
    <div class="user-account__step">
      <div class="user-account__step__title">
        Dados pessoais
      </div>
      <div class="user-account__step__fieldset">
        <v-textbox
          v-model="data.nome"
          :data-vv-as="this.documentType === 'PF' ? 'Nome' : 'Razão Social'"
          data-vv-scope="account"
          :label="this.documentType === 'PF' ? 'Nome:' : 'Razão Social:'"
          name="account.name"
          :placeholder="this.documentType === 'PF' ? 'Nome completo' : 'Razão Social'"
          type="text"
          validate="required|min:3|max:100" required
          :disabled="this.isUserNameDisabled"/>
        <v-textbox
          v-model="birthDate"
          :data-vv-as="this.documentType === 'PF' ? 'Data de nascimento' : 'Data de Abertura da Empresa'"
          data-vv-scope="account"
          :label="this.documentType === 'PF' ? 'Data de nascimento:' : 'Data de Abertura da Empresa:'"
          mask="##/##/####"
          name="account.birthDate"
          placeholder="dd/mm/aaaa"
          type="tel"
          validate="required|date_format:dd/MM/yyyy" required
          :disabled="this.isBirthNameDisabled"/>
        <v-textbox
          v-model="unmaskedCPF"
          data-vv-as="CPF"
          data-vv-scope="account"
          label="CPF:"
          v-if="this.documentType === 'PF'"
          mask="###.###.###-##"
          name="account.cpf"
          placeholder="000.000.000-00"
          type="tel"
          validate="required|cpf_format|cpf" required
          disabled
          />
        <v-textbox
          v-if="this.documentType === 'PJ'"
          v-model="unmaskedCNPJ"
          data-vv-as="CNPJ"
          label="CNPJ"
          mask="##.###.###/####-##"
          data-vv-scope="account"
          name="account.cnpj"
          placeholder="00.000.000/0000-00"
          type="tel"
          validate="required|cnpj_format|cnpj" required/>
        <v-textbox
          class="show-mother-error"
          v-if="this.documentType === 'PF'"
          v-model="data.nomeMae"
          data-vv-as="Nome da mãe completo"
          data-vv-scope="account"
          :maxlength="200"
          label="Nome da mãe (nome completo conforme documento):"
          name="account.mothersName"
          placeholder="Nome da mãe (nome completo conforme documento)"
          type="text"
          validate="required|min:3|max:200" required
          :disabled="isMotherNameDisabled" />
        <!-- <v-textbox
          v-if="this.documentType === 'PJ'"
          v-model="data.representante"
          data-vv-as="Representante"
          data-vv-scope="account"
          :maxlength="200"
          label="Representante:"
          name="account.representante"
          placeholder="Nome do representante"
          type="text"
          validate="required|min:3|max:200" required />
        <v-textbox
          v-model="unmaskedCPF"
          data-vv-as="CPF do Representante"
          data-vv-scope="account"
          label="CPF do Representante:"
          v-if="this.documentType === 'PJ'"
          mask="###.###.###-##"
          name="account.cpf"
          placeholder="000.000.000-00"
          type="tel"
          validate="required|cpf_format|cpf" required/> -->
      </div>
    </div>
    <div class="user-account__step">
      <div class="user-account__step__title">
        Endereço e Contato
      </div>
      <div class="user-account__step__fieldset">
        <v-textbox
          v-model="unmaskedCEP"
          v-on:keyup.native="cepInput"
          data-vv-as="CEP"
          data-vv-scope="account"
          label="CEP:"
          mask="#####-###"
          name="account.cep"
          placeholder="00000-000"
          type="tel"
          validate="required|cep" required/>
        <v-textbox
          v-model="address.logradouro"
          data-vv-as="Logradouro"
          data-vv-scope="account"
          label="Logradouro:"
          name="account.street"
          type="text"
          validate="required" required/>
        <v-textbox
          v-model="address.numero"
          data-vv-as="Número"
          data-vv-scope="account"
          v-on:keyup.native="houseNumber"
          label="Número:"
          name="account.number"
          type="text" required/>
        <v-textbox
          v-model="address.complemento"
          data-vv-as="Complemento"
          data-vv-scope="account"
          label="Complemento:"
          name="account.complement"
          placeholder="Ex.: Apto 12"
          validate="min:4|max:30"
          type="text"/>
        <v-textbox
          v-model="address.bairro"
          data-vv-as="Bairro"
          data-vv-scope="account"
          label="Bairro:"
          name="account.neighborhood"
          type="text"
          validate="required" required/>
        <v-textbox
          v-model="address.cidade"
          data-vv-as="Cidade"
          data-vv-scope="account"
          label="Cidade:"
          name="account.city"
          type="text"
          validate="required" required/>
        <v-textbox
          v-model="address.uf"
          data-vv-as="Estado"
          data-vv-scope="account"
          label="Estado:"
          name="account.state"
          placeholder="Selecione"
          type="text"
          mask="AA"
          validate="required|max:2" required/>
        <v-textbox
          v-model="phone"
          :mask="['(0##) ####-####', '(0##) #####-####', '(0##) # ####-####']"
          data-vv-as="Telefone Celular"
          data-vv-scope="account"
          label="Telefone Celular:"
          name="account.phone"
          type="tel"
          validate="required|phone"
          placeholder="000 0 0000-0000"
          :disabled="isPhoneDisabled"
          required/>
      </div>
    </div>
    <div class="user-account__slot">
      <slot/>
    </div>
  </div>
</template>

<style lang="postcss" scoped>

.user-account {
  &.responsive {
    @media (min-width: 992px) {
      ^& {
        background-color: var(--color-white);
        border-radius: .5rem;
        margin: 0 auto;
        width: 976px;
        &__step {
          display: flex;
          &__title {
            margin-right: 6rem;
            width: 342px;
          }
          &__fieldset {
            margin-top: 0;
            width: 538px;
          }
        }
      }
    }
  }
  &__step {
    padding-top: 1.5rem;
    &__title {
      color: var(--color-blue-onlypay);
      font-size: 1.5rem;
      font-weight: 300;
    }
    &__fieldset {
      margin-top: 1.5rem;
      &__field {
        &-full {
          width: 100%;
        }
      }
      &__password-info {
        margin-bottom: 2rem;
        &__title {
          padding-bottom: 1rem;
        }
        &__item {
          padding: .25rem 0;
          &.on {
            ^&__icon {
              color: var(--color-green);
            }
          }
          &__icon {
            color: var(--color-grey-light);
            margin-right: .25rem;
            size: 1.25rem;
          }
          &__text {
            vertical-align: -.125rem;
          }
        }
      }
    }
  }
  &__slot {
    padding-top: 1.5rem;
    &:empty {
      display: none;
    }
  }
}
.show-message-error {
  display: none;
}
.focused.show-mother-error ~ .show-message-error {
  display: block;
}
.has-error.show-mother-error ~ .show-message-error {
  display: none!important;
}

</style>
